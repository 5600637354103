body {
  margin: 0;
  padding: 0;
  background: #000000;
}

canvas {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 300%;
}


div.first {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: 200vh;
}

div.second {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: 200vh;
  
}

div.third {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: 200vh;
}

div.fourth {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: 200vh;
}

div.five {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: 200vh;
}

div.six {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: 200vh;
}
div.scroll {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: 10vh;
}

h1 {
  color: white;
text-align: center;
font-family: sans-serif;
}